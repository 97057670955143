import React from 'react';
import { get, isEmpty } from 'lodash';
import Actions from '@myaccount/actions';
import { useFormState } from "@myaccountServices";
import Alert from '@myaccount/alert';
import StyledDialogTitle from '../../title';
import configs from '@myaccount/actions/action/viewing/defaults';
import CircularProgress from '@myaccount/progress/CircularProgress'

// MUI imports
import { Button, DialogActions, Grid } from '@mui/material';

// Gatsby imports
import { Link } from "gatsby";


/**
 * If attendee_confirmed true say message thankyou for confirming.
 * if attendee_confirmed false display action btns
 */
const FeedbackDialog = ({dialogContentProps, dialogTitleProps, code, type}) => {

    const { state, services } = useFormState()

    const {
        dialogActionsBtnProps,
        dialogCancelActionBtnProps
    } = dialogContentProps

    let actionData = state?.actionData || null;

    React.useEffect(() => {
        services.getTokenValues({code: code, type: type})
    }, [])

    const handleSubmit = (event, value) => {
        event.preventDefault();

        const actionParams = get(configs, 'props.actionItemDefaults.applicant.default', []).filter(a => a.name === (value ? 'confirm' : 'cancel') );        
        const btnParams = get(actionParams, '0.btnParams', {})
        let successMessage = 'Viewing Confirmed. Please visit your Dashboard to further action.';
        if (!value)
            successMessage = "Viewing Cancelled. Please visit your Dashboard to further action."

        const values = {
            ...btnParams,
            successMessage: successMessage,
            appointment_id: actionData.crm_id,
        }

        // console.log("values => ", values)
        services.postForm(values);
    };

    if (state.loading) {
        return <CircularProgress />
    }

    return (
        <>
            {isEmpty(actionData) && (
                <Grid classes={{root: "alertContainer"}}>
                    <Alert type="alert" classes="actionCardDialogChildren-alert" message={state.message} severity={state.error ? 'error': 'success'} />
                    <Button variant="contained" href="/">Go to dashboard</Button>
                </Grid>
            )}
            
            {!isEmpty(actionData) && (
                <>
                    <StyledDialogTitle
                        id="acionDialog-responsive-dialog-title"
                        title={`Please confirm your availability for the following appointment`}
                        dialogTitleProps={dialogTitleProps}
                    />
                    <Actions data={[{...actionData, showActionButton: false}]} />

                    <Grid classes={{root: "dialogActionsConfirmContainer"}}>
                        <DialogActions classes={{root: "dialogActionsConfirm"}}>
                            <Button {...dialogActionsBtnProps} classes={{root: "dialogActionsConfirmBtn"}} onClick={(event) => {handleSubmit(event, true)}}>
                                Yes
                            </Button>
                            <Button {...dialogCancelActionBtnProps} classes={{root: "dialogActionsConfirmBtn"}} onClick={(event) => {handleSubmit(event, false)}}>
                                No
                            </Button>
                        </DialogActions>
                    </Grid>
                </>
            )}
        </>
    )
};

export default React.memo(FeedbackDialog);